//
// general.scss
//

/* ==============
   01. General 
   ============== */

body {
  font-family: $font-family-base;
  position: relative;
  color: $white;
  font-size: $font-size-base;
  background-color: $dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-base;
  font-weight: 500;
  line-height: 32px;
}

.btn,
.btn:focus,
button,
button:focus {
  outline: none !important;
}

a {
  text-decoration: none !important;
  outline: none;
  &:hover {
    color: inherit;
  }
}

p {
  line-height: 24px;
}

.row > * {
  position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[inputmode="numeric"] {
  -moz-appearance: textfield;
}

.user-avatar {
  max-width: 80px;
}

.img-w-100 {
  max-width: 100px;
}
.img-w-62 {
  max-width: 62px;
}
.img-w-60 {
  max-width: 60px;
}
.img-w-64 {
  max-width: 64px;
}
.img-w-40 {
  max-width: 40px;
}
.img-ml-4 {
  margin-left: 4px;
}

.form-control {
  min-height: 56px;
}

.form-control:disabled {
  opacity: 0.6;
  background-color: initial;
}

.form-select:disabled {
  background-color: initial;
  opacity: 0.6;
}

.banner-imager-wrapper {
  max-height: 498px;
  overflow: hidden;
  width: 100%;
  display: flex;
  border-radius: 16px;
  align-items: flex-start;
  position: relative;
}
.rounded-image-container {
  display: flex;
  margin: auto;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  &._40 {
    width: 40px;
    height: 40px;
  }
  &._62 {
    width: 62px;
    height: 62px;
  }
  &._60 {
    width: 60px;
    height: 60px;
  }
  &._64 {
    width: 62px;
    height: 62px;
  }
  &._56 {
    width: 56px;
    height: 56px;
  }
  &._80 {
    width: 80px;
    height: 80px;
  }
}

.spinner-border-lg {
  width: 80px;
  height: 80px;
}
.full-page-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  margin: auto;
  z-index: 9;
}

.mt-16 {
  margin-top: 16px;
}
.mr-4 {
  margin-right: 4px;
}
.flex-1 {
  flex: 1;
}
.free-join-btn {
  min-width: 211px;
}
.nowrap {
  flex-wrap: nowrap !important;
}

.min-h-48 {
  min-height: 48px;
}
.no-record-found {
  text-align: center;
}

.event-help-text {
  margin-top: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;
  letter-spacing: 0.06em;
  text-transform: lowercase;

  color: #fff2de;
}

.no-disable {
  opacity: 1 !important;
}

.btn-border-color {
  border-color: #1AD688 !important;
  &:hover {
    border-color: #1a1e21 !important;
  }
}

/* Animation */

.bounce {
  animation: bounce 2s ease 2;
  animation-delay: 500ms;
}
@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

.ml-8 {
  margin-left: 8px;
}
.ml-0 {
  margin-left: 0px !important;
}
.mr-16 {
  margin-right: 16px;
}

.y-scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -20px;
}

.scroll-black {
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

.open-in-app-wrapper {
  margin-right: 12px;
}

.navbar-nav {
  flex-direction: row;
}

.logo {
  img {
    height: 60px;
    @media (max-width: 768px) {
      height: 45px;
    }
  }
}