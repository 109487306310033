//
// variables.scss
//

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

$custom:    #FFF2DE;
$primary:   #215ae7;
$success:   #0e5043;
$info:      #229bee;
$warning:   #f6cb42;
$danger:    #fb3e3e;
$orange:    #f09105;
$purple:    #392f4d;
$cyan:      #0b2a39;
$blue:      #081540;
$pink:      #3a2535;
$white:     #ffffff;
$dark:      #0d1114;
$dark-50:   #161617;
$light:     #B9B9B9;
$muted:     #D6D6D6;

// stylelint-disable
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #efefef;
$gray-400: #e6effe;
$gray-500: #adb5bd;
$gray-600: #191e23;
$gray-700: #20252a;
$gray-800: #171718;
$gray-900: #050708;

$colors: (
    "custom": $custom,
    "primary": $primary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "orange": $orange,
    "dark": $dark,
    "dark-50": $dark-50,
    "cyan": $cyan,
    "blue": $blue,
    "muted": $muted,
    "purple": $purple,
    "pink": $pink,
    "white": $white,
    "light": $light
);


// Body Background
$body-bg:                   #f5f5f5;
$bg-gradient:               linear-gradient(to right, $primary, $info);
$footer-bg:                 #0a0a0a;
$box-shadow:                0px 2px 10px 0px rgba(22, 85, 197, 0.06);


// Dropdown

$dropdown-bg:                   #0D1114;


// Form Control

$input-bg:                   #11161B;
$input-border:               rgba(255, 255, 255, 0.2);


// Base font
$font-size-base:            16px;

$font-family-base:          'Poppins', sans-serif;
$font-family-secondary:     'Poppins', sans-serif;