.member_count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 158.72%;

  letter-spacing: 0.06em;
  text-transform: lowercase;
  margin-top: 8px;
  display: block;
  color: #d6d6d6;
}

.community-side-img-wrapper {
  width: 80px;
  height: 80px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.community-side-desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

  /* White */

  color: #ffffff;
}

.home-desc {
  font-family: "Poppins", sans-serif;
  &.parse {
    * {
      font-family: "Poppins", sans-serif !important;
      color: #d6d6d6 !important;
      font-size: 16px !important;
    }
  }
}

.grayscale {
  filter: grayscale(1);
}

.card-overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
}
.community-title-contaner {
  display: flex;
  flex-direction: row;

  .profile-image-wrapper {
    margin-right: 16px;
  }
  .community-title-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.community-membership-btn {
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid #1AD688;
  border-radius: 40px;
  padding: 11px;
  margin-top: 12px;
}

.is-member {
  margin-top: -8px;
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */

    letter-spacing: 0.06em;

    /* Icon Color */

    color: #fff2de;
  }
}

.community-content-top {
  min-height: 135px;

  @media (max-width: 768px){
    min-height: initial;
  }
}