//Import Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./style.scss";

// custom card 
.event-box:not(:first-child){
    margin-top: 1.5rem!important;
}

.terms-conditions{
    position:absolute;
    left:0;
    right: 0;
    margin-top: 20px;

}

//Two verification Page
.verification{
    width: 64px;
    min-height: 68px;
    padding: 8px;
    border-radius: 10px;
    font-size: 1.25rem;
    text-align: center;
    margin-right: 6px;
    text-transform: uppercase;
    display: inline-block;
    @media (max-width: 768px){
        width: 56px;
        min-height: 56px;
        font-size:17px;
      }
      @media (max-width: 425px){
        width: 48px !important;
        min-height: 48px !important;
        font-size: 15px;
      }
      @media (max-width: 320px){
        width: 38px !important;
        min-height: 38px !important;
        font-size: 13px;
      }
}
