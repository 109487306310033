//
// menu.scss
//

.navbar-custom {
  height: 84px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
  background-color: $gray-600;
  padding: 0;
}


.nav-button{
  .dropdown-menu{
    &.show{
      @media (max-width: 625px){
        display: none;
      }
    }
  }
}