//
// home.scss
//

.home-slider {
  /* position: relative; */
  position: static !important;
  width: 100%;
}

.home-slider .carousel-control-next,
.carousel-control-prev {
  width: 80px;
}

.home-slider .carousel-item {
  background-position: center center;
  background-size: cover;
  img {
    max-width: 100%;
    aspect-ratio: 16/9;
  }
}

.home-slider {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: $gray-800;
    background-size: 100% 40%;
    border-radius: 50%;
  }
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.time-tag {
  padding: 2px 20px;
  color: $white;
  background-color: $gray-900;
  display: inline-block;
  border-radius: 30px;
  font-size: 14px;
  letter-spacing: 0.6px;
  text-transform: lowercase;
  @media (max-width: 830px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
}

.titlize {
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.home-title {
  font-size: 24px;
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
  @media (max-width: 830px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.home-heading {
  font-size: 18px;
  @media (max-width: 830px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.home-desc {
  @media (max-width: 830px) {
    font-size: 13px;
    line-height: 21px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.home-plus-icon {
  @media (max-width: 830px) {
    width: 26px;
  }
}

// creators

.verified-border {
  border: 1px solid #489cff;
}

.verified-icon {
  position: relative;
  .icon {
    position: absolute;
    right: 0;
    margin-top: -22px;
  }
  .icon-sm {
    position: absolute;
    right: 0;
    margin-top: -17px;
    @media (max-width: 830px) {
      width: 12px;
      margin-top: -13px;
    }
  }
}

.card-sidebar {
  border: 1px solid #474b50;
  border-radius: 16px;
  position: sticky;
  overflow-y: auto;
  top: 25px;
  .card-footer {
    border-top: 1px solid #474b50;
  }
}

// PASS

.pass-banner-img {
  @media (max-width: 830px) {
    width: 226px;
  }

  @media (max-width: 425px) {
    text-align: center;
    margin: 0 auto;
  }
  img {
    @media (max-width: 425px) {
      width: 250px;
    }
  }
}

.social-img {
  img {
    @media (max-width: 830px) {
      height: 18px;
    }
  }
}

.pass-user-img {
  @media (max-width: 830px) {
    width: 40px;
  }
  @media (max-width: 425px) {
    width: 36px;
  }
}

.transaction-border {
  border: 0.825206px dashed #1c1c1c;
}

.transaction-heading {
  font-size: 24px;
  @media (max-width: 830px) {
    font-size: 18px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.transaction-desc {
  font-size: 18px;
  @media (max-width: 830px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.transaction-title {
  font-size: 22px;
  line-height: 29px;
  @media (max-width: 830px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.transaction-amount {
  font-size: 22px;
  @media (max-width: 830px) {
    font-size: 18px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.alert-icon-top {
  img {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media (max-width: 830px) {
      width: 56px;
      top: -30px;
    }
    @media (max-width: 425px) {
      width: 40px;
      top: 10px;
    }
  }
  .avatar-title {
    box-shadow: inset 3px 2px 10px rgba(0, 0, 0, 0.4),
      inset -3px -1px 10px rgba(255, 255, 255, 0.1);
  }
}

.pass-banner-img {
  img {
    @media (max-width: 830px) {
      width: 226px;
    }
    @media (max-width: 425px) {
      margin: 0 auto;
    }
  }
}

.pass-star-img {
  @media (max-width: 830px) {
    width: 64px;
    height: 64px;
  }
}

.pass-effact {
  background-image: url(../images/pass_image.png);
  background-size: auto;
  background-repeat: repeat-y;
  & > div {
    background-color: #fdeed3;
    height: 100%;
  }
  @media (max-width: 830px) {
    // padding: 32px 25px;
  }
  @media (max-width: 767px) {
    padding-top: 10px;
    background-image: url(../images/pass_image_top-cut.png);
    background-repeat: repeat-x;
  }
}

.trans-unsucce-bg {
  background-image: url(../images/alert-effact.png);
  background-size: contain;
  background-repeat: no-repeat;
  .time-tag {
    background-color: rgba(0, 0, 0, 0.6);
    color: $white;
    font-size: 16px;

    @media (max-width: 830px) {
      font-size: 14px;
    }
    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
}

@media (max-width: 425px) {
  .transaction-user {
    margin: auto;
    margin-top: 20px;
  }
}

// Download

.download-box {
  border: 2px solid rgba(46, 46, 47, 1);
  box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.4);
}

.download-section {
  margin-top: 32px;
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    letter-spacing: 0.06em;
    color: #ffffff;
  }
  .download-app-row {
    @media (max-width: 1024px) { 
      flex-direction: column;
    }
  }
}

.download-title {
  line-height: 42px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.download-desc {
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.download-img {
  img {
    position: absolute;
    top: -140px;
    left: 30px;
    right: 0;
    margin: 0 auto;
    @media (max-width: 1024px) {
      position: inherit;
      top: 0px;
    }
  }
}

// PROFILE

.profile-blog-post {
  font-size: 18px;
  @media (max-width: 830px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.profile-blog-cues {
  font-size: 20px;
  @media (max-width: 830px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
}

.cues-img-post {
  width: 347px;
  height: 228px;
  max-width: 100%;
  // height: 197px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
  //   #003f5c;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  padding: 0;
  margin-left: 14px;
  img {
    width: 100%;
    aspect-ratio: 16/9;
    height: 100%;
  }
  @media (max-width: 425px) {
    text-align: center;
    margin-left: 0px;
    box-shadow: none;
  }
  img {
    @media (max-width: 425px) {
      // width: 180px;
      border-radius: 16px;
    }
  }
}

.cover-image-wrapper {
  flex: 0;
  max-width: 100%;
  @media (max-width: 425px) {
    display: flex;
    flex: none !important;
    justify-content: center;
  }
}

.cues-contect {
  flex: 1;
  margin-left: 5px;
  .in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 720px) {
      height: auto;
    }
  }
}

.profile-verified-border {
  border: 2px solid #489cff;
  @media (max-width: 830px) {
    width: 88px;
    height: 88px;
  }
  @media (max-width: 425px) {
    width: 64px;
    height: 64px;
  }
}

.profile-img {
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  @media (max-width: 830px) {
    top: -48px;
  }
  @media (max-width: 720px) {
    top: -65px;
  }
}
.profile-img-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 100%;
  margin: auto;
}

.profile-verified-icon {
  position: relative;
  .icon {
    position: absolute;
    margin-left: 17px;
    margin-top: -22px;
  }
  .icon-sm {
    position: absolute;
    right: 0;
    margin-top: -17px;
  }
}

.profile-tab {
  .nav-pills {
    .nav-item {
      position: relative;
      margin-right: 40px;
      @media (max-width: 830px) {
        margin-right: 35px;
      }
      @media (max-width: 425px) {
        margin-right: 22px;
      }
      .nav-link {
        color: $muted;
        padding-top: 0.1rem;
        padding-bottom: 0.2rem;
        font-size: 18px;
        @media (max-width: 830px) {
          font-size: 14px;
        }
        @media (max-width: 425px) {
          font-size: 12px;
        }
        &.active {
          background-color: transparent;
          border-bottom: 1px solid #20c883;
          color: $custom;
          border-radius: 0px;
          padding-top: 0.1rem;
          padding-bottom: 0.2rem;
        }
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

// Event BOx

.event-box {
  border: 1px solid rgba($white, 0.02);
  .event-box-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 236px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 13px;
    img {
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 13px;
    }
  }
}

// Pass card
.pass-card-wrapper {
  padding: 24px;
  @media (max-width: 1024px) {
    padding: 26px 12px 28px 12px;
    .pass-title {
      font-size: 18px;
    }
    & > .row {
      flex-direction: column;
    }
    .avatar-group {
      padding-left: 0;
      .avatar-group-item {
        margin-left: 0;
      }
    }
    .rounded-image-container._64 {
      width: 40px !important;
      height: 40px !important;
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}

.pass-effact {
  @media (max-width: 1024px) {
    .transaction-title {
      font-size: 16px;
    }
    .transaction-desc {
      font-size: 14px;
    }

    .pass-star-img {
      width: 64px;
      height: 64px;
    }
    .transaction-amount {
      font-size: 18px;
    }
  }
}
