//
// components.scss
//

.btn {
  padding: 11px 30px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.5s;

  &:focus {
    box-shadow: none;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.btn-lg {
  padding: 14px 41px;
  @media (max-width: 830px) {
    padding: 12px 54px;
    font-size: 14px;
  }
  @media (max-width: 425px) {
    padding: 7px 37px;
    font-size: 12px;
  }
  &.by-pass-btn {
    @media (max-width: 830px) {
      padding: 5px 25px;
    }
  }
}

.profile-tab-button {
  font-weight: 400;
  border: 1px solid $input-border !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

// card

.card {
  background-color: $gray-600;
  border-radius: 12px;
}

.card-border {
  border: 1px solid #474b50;
}

.btn-sm {
  padding: 8px 12px;
  font-size: 12px;
}

.shadow {
  box-shadow: $box-shadow !important;
}

.btn-outline-custom {
  min-width: 130px;
  font-weight: 300;
  @media (max-width: 830px) {
    min-width: 170px;
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
  &:hover,
  &:focus {
    color: $dark;
  }
  &.navbar-btn {
    @media (max-width: 830px) {
      min-width: 90px;
      padding: 6px 15px;
    }
  }
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }

  .bg-soft-#{$name} {
    background-color: rgba(($value), 0.1) !important;
  }
  .text-#{$name} {
    color: #{$value} !important;
  }

  .border-#{$name} {
    color: #{$value} !important;
  }

  // Icons
  .icon-dual-#{$name} {
    color: $value;
    fill: rgba($value, 0.2);
  }

  .btn-soft-#{$name} {
    background-color: rgba(($value), 0.25) !important;
    color: #{$value} !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: darken($value, 4%);
    }
  }

  .btn-#{$name} {
    background: #{$value};
    border-color: #{$value};
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }

  .btn-outline-#{$name} {
    color: #{$value};
    border-color: #{$value};
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: $value;
      border-color: $value;
    }
  }
}

// Badge

.badge {
  font-weight: 400;
  padding: 0.38em 0.72em;
  border-radius: 8px;
  display: inline-block;
  text-transform: lowercase;
  &.bg-light {
    background-color: $gray-700 !important;
    font-size: 16px;
    img {
      @media (max-width: 830px) {
        width: 16px;
        height: 16px;
      }
    }
    @media (max-width: 830px) {
      font-size: 14px;
    }
    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
}
.emoji {
  padding: 0 2.5px;
  border-radius: 8px;
  background-color: #ffd5ae;
  text-align: center;
  margin-right: 4px;
  min-width: 22px;
  display: inline-flex;
  justify-content: center;
  height: 22px;
  align-items: center;
  position: relative;
  .dot {
    background-color: rgb(25, 30, 36);
    width: 3.8px;
    height: 3.8px;
    border-radius: 100%;
    left: -1px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    position: absolute;

    &:first-child {
      right: -1px;
      left: initial;
    }
  }
}

.best_suited_tag {
  .emoji {
    background-color: #fbe081;
  }
}
.lang_tag {
  .emoji {
    background-color: #ff9d43;
  }
}

.badge {
  font-weight: 400;
  padding: 5px 6px;
  border-radius: 4px;
  &.bg-dark {
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5) !important;
    img {
      @media (max-width: 830px) {
        width: 16px;
        height: 16px;
      }
    }
    @media (max-width: 830px) {
      font-size: 13px;
    }
    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
}

// DROPDOW

.dropdown-menu {
  background-color: $dropdown-bg;
  border: 1px solid #505050;
  box-shadow: 0px -6px 4px rgba(0, 0, 0, 0.25),
    0px 14px 18px rgba(0, 0, 0, 0.172886);
  width: 220px;
  border-radius: 16px;
  padding-bottom: 0px;
  .dropdown-item {
    color: $white;
    padding: 11px 20px;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
  .dropdown-divider {
    border-top: 1px solid $input-border;
    margin: 0;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 45px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background: $dropdown-bg;
    border: 1px solid #cacdcf;
    border-color: transparent transparent #505050 #505050;
    transform: rotate(135deg);
    border-bottom-left-radius: 4px;
  }
}
.dropdown-user {
  line-height: 85px;
}

// MODAl

.modal-content {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.03),
      rgba(255, 255, 255, 0.03)
    ),
    #191e23;
  border: 1px solid $input-border;
  border-radius: 16px;
}

.model-img {
  position: relative;
  img {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 196px;
    max-height: 110px;
  }
}

.modal-border {
  border: 1px solid $input-border;
  width: 50%;
  margin: 0 auto;
}

.modal-desc {
  @media (max-width: 830px) {
    font-size: 14px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
}

.modal-head {
  font-size: 18px;
  @media (max-width: 830px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
  }
}

// FORM

.modal {
  background-color: rgba($dark, 0.8);
}

.modal-body {
  padding: 1.5rem;
}

.modal-selact {
  background-color: $input-bg;
  border: 1.29167px solid $input-border;
  border-radius: 10px;
  padding: 0.795rem 1.8rem;
  max-width: 130px;
  font-size: 24px;
  color: rgba($white, 0.5);
  background-size: 55px 16px;
  font-weight: 300;
  @media (max-width: 830px) {
    padding: 0.795rem 1.8rem;
    font-size: 18px;
    background-size: 32px 16px;
    max-width: 110px;
  }
  @media (max-width: 425px) {
    padding: 0.67rem 1.2rem;
    font-size: 16px;
    background-size: 28px 16px;
    max-width: 90px;
  }
  &:focus {
    background-color: $input-bg;
    box-shadow: none;
    border-color: $input-border;
  }
}

.modal-input {
  background-color: $input-bg;
  border: 1.29167px solid $input-border;
  border-radius: 10px;
  font-size: 24px;
  padding: 0.375rem 1.2rem;
  color: rgba($white, 0.5) !important;
  font-weight: 300;
  @media (max-width: 830px) {
    font-size: 18px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
  &::placeholder {
    color: rgba($white, 0.5);
  }
  &:focus {
    background-color: $input-bg;
    box-shadow: none;
    border-color: $input-border;
  }
  &.form-control-lg {
    padding: 1.045rem 3.2rem;
    border-radius: 16px;
    @media (max-width: 830px) {
      padding: 0.795rem 3.2rem;
      font-size: 18px;
    }
    @media (max-width: 425px) {
      padding: 0.67rem 3.2rem;
      font-size: 16px;
    }
  }
  &.form-control-md {
    padding: 0.795rem 1.2rem;
    border-radius: 10px;
    font-size: 24px;
    @media (max-width: 830px) {
      padding: 0.795rem 3.2rem;
      font-size: 18px;
    }
    @media (max-width: 425px) {
      padding: 0.67rem 3.2rem;
      font-size: 16px;
    }
  }
}

.modal-search {
  position: relative;
  input {
    font-size: 18px;
    padding-left: 55px;
  }
  .search-icon {
    position: absolute;
    top: 22px;
    left: 20px;
    @media (max-width: 830px) {
      top: 18px;
    }
    @media (max-width: 425px) {
      top: 14px;
    }
  }
}

.search-list {
  li {
    padding: 10px 22px;
    @media (max-width: 830px) {
      padding: 9px 14px;
    }
    @media (max-width: 425px) {
      padding: 8px 12px;
    }
    &:first-child {
      padding-top: 0;
    }
    a {
      font-size: 24px;
      @media (max-width: 830px) {
        font-size: 16px;
      }
      @media (max-width: 425px) {
        font-size: 14px;
      }
    }
  }
}

.form-control {
  background-color: $input-bg;
  border: 1.29167px solid $input-border;
  color: rgba($white, 0.5) !important;
  border-radius: 10px;
  font-weight: 300;
  &::placeholder {
    color: rgba($white, 0.5);
  }
  &:focus {
    background-color: $input-bg;
    border-color: $input-border;
    box-shadow: none;
  }
}

// OOFcanvas

.login-offcanvas {
  height: auto;
  background-color: $gray-600;
  border: 1px solid rgba($white, 0.2);
  border-radius: 32px 32px 0px 0px;
  .dropdown-item {
    color: #f3f3f3;
    padding: 20px 0px;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
  .dropdown-divider {
    border-top: 1px solid $input-border;
    margin: 0;
  }
}
