//
// helper.scss
//

//
// helper.scss
//


.box-shadow {
  box-shadow: $box-shadow;
}

hr {
  border-top: 1px solid $muted;
  margin: 0;
}

.section{
  padding: 100px 0px;
}


.btn-rounded {
  border-radius: 30px;
}


// font-size

.f-10 {
  font-size: 10px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-19 {
  font-size: 19px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-23 {
  font-size: 23px !important;
}

.f-24 {
  font-size: 24px !important;
}


// width

.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.w-xxl {
  min-width: 210px;
}

// font-weight

.fw-semibold {
  font-weight: 600;
}

.fw-medium{
  font-weight: 500;
}
.fw-300{
  font-weight: 300;
}

// Rounded

.rounded-16{
  border-radius: 16px;
}


// TEXT-LINE

.truncate-line-2{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}


.avatar-xs {
  height: 1.375rem;
  width: 1.375rem;
}

.avatar-sm {
  height: 2.125rem;
  width: 2.125rem;
}

.avatar {
  height: 2.6rem;
  width: 2.6rem;
}

.avatar-md {
  height: 3rem;
  width: 3rem;
  @media (max-width: 768px){
    height: 2.5rem;
    width: 2.5rem;
  }
  @media (max-width: 425px){
    height: 2.25rem;
    width: 2.25rem;
  }
}

.avatar-lg {
  height: 3.5rem;
  width: 3.5rem;
  @media (max-width: 768px){
    height: 2.5rem;
    width: 2.5rem;
  }
  @media (max-width: 425px){
    height: 2.25rem;
    width: 2.25rem;
  }
}

.avatar-xl {
  height: 6rem;
  width: 6rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// avatar group
.avatar-group {
  display: flex;
  flex-wrap: wrap;
  padding-left: 8px;
  .avatar-group-item {
    margin-left: -8px;
    border-radius: 50%;
    transition: all 0.2s;
  }
}
